import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { partneringRequestForm } from '@sporkbytes/api-client/PublicPartners';
import {
	Alert,
	Form,
	LinkedPhoneNumberInput,
	LinkedSingleSelect,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import ThankYou from './ThankYou';
import withRecaptcha from './withRecaptcha';

import axios from 'services/axios';

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(2),
	},
	section: {
		marginBottom: theme.sizes.componentSpacing / 2,
	},
	submitButton: {
		marginTop: theme.spacing(2),
	},
}));

const validationSchema = Yup.object().shape({
	businessName: Yup.string().required('Please enter your business name.'),
	phoneNumber: Yup.string().required('Please enter a phone number.'),
	description: Yup.string().required('Please enter a description.'),
	firstName: Yup.string().required('Please enter your first name.'),
	lastName: Yup.string().required('Please enter your last name.'),
	emailAddress: Yup.string()
		.email('Please enter a valid email address.')
		.required('Please enter your email address.'),
});

const getReferences = data => [
	'Friend',
	'Online Search',
	'Press Article',
	'Flyer/Brochure',
	`${data.site.siteMetadata.title} Employee`,
	'Social Media',
	'Other',
];

const PartneringRequestForm = ({ getRecaptchaToken }) => {
	const classes = useStyles();
	const [error, setError] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const data = useStaticQuery(graphql`
		query GetSiteMetadata {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	const Header = props => (
		<Typography
			component="h2"
			variant="h4"
			{...props}
			className={classes.header}
		/>
	);

	return isSubmitted ? (
		<ThankYou
			primaryText="Thank you for your interest in partnering with us!"
			secondaryText={`A ${data.site.siteMetadata.title} representative will contact you soon.`}
		/>
	) : (
		<>
			<Form
				initialValues={{
					businessName: '',
					phoneNumber: '',
					description: '',
					firstName: '',
					lastName: '',
					emailAddress: '',
					reference: '',
				}}
				onSubmit={async (values, actions) => {
					try {
						const recaptchaResponse = await getRecaptchaToken();
						await partneringRequestForm(axios, {
							...values,
							recaptchaResponse,
						});

						setError();
						setIsSubmitted(true);
					} catch (err) {
						setError(err.response.data.message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
				onCancel={null}
				validationSchema={validationSchema}
			>
				{() => (
					<>
						<section className={classes.section}>
							<Header>Business Details</Header>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<LinkedTextInput
										name="businessName"
										label="Business Name"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<LinkedPhoneNumberInput name="phoneNumber" />
								</Grid>
								<Grid item xs={12}>
									<LinkedTextInput
										name="description"
										placeholder="What kind of food do you make? Where are you located? Feel free to share any other details you think are important."
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</section>
						<section className={classes.section}>
							<Header>Your Information</Header>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<LinkedTextInput name="firstName" />
								</Grid>
								<Grid item xs={12} sm={6}>
									<LinkedTextInput name="lastName" />
								</Grid>
								<Grid item xs={12} sm={6}>
									<LinkedTextInput
										name="emailAddress"
										type="email"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<LinkedSingleSelect
										name="reference"
										label="How did you hear about us? (optional)"
										options={getReferences(data)}
										inputProps={{ id: 'reference' }}
										data-testid="reference"
									/>
								</Grid>
							</Grid>
						</section>
					</>
				)}
			</Form>
			{error && <Alert type="error">{error}</Alert>}
		</>
	);
};

export default withRecaptcha(PartneringRequestForm);
